body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.code-editor {
  width: 100% !important;
}

@font-face {
  font-family: 'Metropolis';
  src: url('../fonts/Metropolis/Metropolis-Regular.woff2') format('woff2'),
      url('../fonts/Metropolis/Metropolis-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Metropolis';
  src: url('../fonts/Metropolis/Metropolis-Light.woff2') format('woff2'),
      url('../fonts/Metropolis/Metropolis-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Metropolis';
  src: url('../fonts/Metropolis/Metropolis-SemiBold.woff2') format('woff2'),
      url('../fonts/Metropolis/Metropolis-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Metropolis';
  src: url('../fonts/Metropolis/Metropolis-Bold.woff2') format('woff2'),
      url('../fonts/Metropolis/Metropolis-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

:root {
  --white-color:                  #ffffff;
  --primary-color:                #0F9D58;
  --primary-color-hover:          #5bc1ac;
  --secondary-color:              #5a6f80;
  --section-bg-color:             #f0f8ff;
  --site-footer-bg-color:         #44525d;
  --custom-btn-bg-color:          #597081;
  --custom-btn-bg-hover-color:    #5bc1ac;
  --dark-color:                   #000000;
  --p-color:                      #717275;
  --border-color:                 #e9eaeb;

  --body-font-family:             'Metropolis', sans-serif;

  --h1-font-size:                 52px;
  --h2-font-size:                 46px;
  --h3-font-size:                 32px;
  --h4-font-size:                 28px;
  --h5-font-size:                 24px;
  --h6-font-size:                 22px;
  --p-font-size:                  16px;
  --btn-font-size:                18px;
  --copyright-font-size:          14px;

  --border-radius-large:          100px;
  --border-radius-medium:         20px;
  --border-radius-small:          10px;

  --font-weight-light:            300;
  --font-weight-normal:           400;
  --font-weight-semibold:         600;
  --font-weight-bold:             700;
}

body {
  background-color: var(--white-color);
  font-family: var(--body-font-family); 
}

/*---------------------------------------
  TYPOGRAPHY               
-----------------------------------------*/

h2,
h3,
h4,
h5,
h6 {
  color: var(--dark-color);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: var(--font-weight-semibold);
  letter-spacing: -1px;
}

h1 {
  font-size: var(--h1-font-size);
  font-weight: var(--font-weight-bold);
  letter-spacing: -2px;
}

h2 {
  color: var(--secondary-color);
  font-size: var(--h2-font-size);
  letter-spacing: -2px;
}

h3 {
  font-size: var(--h3-font-size);
}

h4 {
  font-size: var(--h4-font-size);
}

h5 {
  color: var(--primary-color);
  font-size: var(--h5-font-size);
}

h6 {
  font-size: var(--h6-font-size);
}

p {
  color: var(--p-color);
  font-size: var(--p-font-size);
  font-weight: var(--font-weight-light);
}

ul li {
  color: var(--p-color);
  font-size: var(--p-font-size);
  font-weight: var(--font-weight-normal);
}

a, 
button {
  touch-action: manipulation;
  transition: all 0.3s;
}

a {
  color: var(--p-color);
  text-decoration: none;
}

a:hover {
  color: var(--primary-color);
}

b,
strong {
  font-weight: var(--font-weight-bold);
}