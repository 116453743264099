.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

main {
  background-color: transparent;
}

.centered {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 15px;
}

.screen_content {
  margin-top: 15vh;
  margin-bottom: 10vh;
  margin-left: 5vw;
  margin-right: 5vw;
}

.sel_margin {
  margin-left: 15px;
  margin-right: 15px;
}

.w_75 {
  width: 75%;
}